import React, { useMemo } from 'react';
import { alpha } from '@mui/material';

import Box, { BoxProps } from 'components/@ui/Box';
import When from 'components/@main/When';
import Icon from 'components/@ui/Icon';
import TypographyMobile from 'components/@ui/TypographyMobile';
import Button from 'components/@ui/Button';
import { useIsTablet, useOpenModal, useProject } from '@hooks';

import MainInformation from './MainInformation';
import OutSailFeatures from './OutSailFeatures';

type HideBlockWrapperProps = {
  isShowText?: boolean;
  isShowButton?: boolean;
  isWrapperOnContent?: boolean;
  contentWrapperProps?: BoxProps;
};

type HideBlockContentProps = Pick<HideBlockWrapperProps, 'isShowText' | 'isShowButton'> & {
  handleOpenFeaturesModal: () => void;
  handleOpenInfoModal: () => void;
};

const HideBlockContent: React.FC<HideBlockContentProps> = ({
  isShowText,
  isShowButton,
  handleOpenInfoModal,
  handleOpenFeaturesModal,
}) => {
  const isTablet = useIsTablet();

  return (
    <>
      <When condition={isShowText} disabledWrapper>
        <Icon name="lock" borderRadius={0} />
        <TypographyMobile my={1.5} semiBold mobileRegularFontSize={isTablet} textAlign="center">
          Join OutSail and unlock all of these{' '}
          <TypographyMobile
            role="button"
            onClick={handleOpenFeaturesModal}
            component="span"
            color="blue.main"
            semiBold
            mobileRegularFontSize={isTablet}
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
          >
            free features
          </TypographyMobile>
        </TypographyMobile>
      </When>
      <When condition={isShowButton} disabledWrapper>
        <Button
          startIcon={<Icon name="unlock-path" color="inherit" borderRadius={0} />}
          sx={{
            p: '4px 24px',
            height: { mobile: 40, laptop: 32 },
            fontSize: { mobile: 18, laptop: 16 },
          }}
          onClick={handleOpenInfoModal}
        >
          Unlock for free
        </Button>
      </When>
    </>
  );
};

const HideBlockWrapper: React.FC<BoxProps & HideBlockWrapperProps> = ({
  children,
  isShowButton = true,
  isShowText = true,
  isWrapperOnContent = false,
  contentWrapperProps,
  ...other
}) => {
  const { hiddenInformation } = useProject();

  const dialogInfoModal = useOpenModal();
  const dialogFeaturesModal = useOpenModal();

  return useMemo(
    () => (
      <When disabledWrapper condition={hiddenInformation} fallback={children}>
        {children}
        <MainInformation
          openModal={dialogInfoModal.open}
          closeModal={dialogInfoModal.handleClose}
        />
        <OutSailFeatures
          closeModal={dialogFeaturesModal.handleClose}
          openModal={dialogFeaturesModal.open}
        />
        <Box
          position="absolute"
          width={1}
          height={400}
          sx={{
            backdropFilter: 'blur(4px)',
            bgcolor: (theme) => alpha(theme.palette.secondary.main, 0.6),
          }}
          zIndex={15}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          {...other}
        >
          <When
            condition={isWrapperOnContent}
            disabledWrapper
            fallback={
              <HideBlockContent
                isShowButton={isShowButton}
                isShowText={isShowText}
                handleOpenFeaturesModal={dialogFeaturesModal.handleOpen}
                handleOpenInfoModal={dialogInfoModal.handleOpen}
              />
            }
          >
            <Box {...contentWrapperProps} >
              <HideBlockContent
                isShowButton={isShowButton}
                isShowText={isShowText}
                handleOpenFeaturesModal={dialogFeaturesModal.handleOpen}
                handleOpenInfoModal={dialogInfoModal.handleOpen}
              />
            </Box>
          </When>
        </Box>
      </When>
    ),
    [
      dialogInfoModal,
      children,
      other,
      dialogFeaturesModal,
      hiddenInformation,
      isShowButton,
      isShowText,
      isWrapperOnContent,
      contentWrapperProps,
    ],
  );
};

export default HideBlockWrapper;
